import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import Footer from "../Common/Footer";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import Loader from "../Common/Loader";
import axios from "axios";
import { config } from "../../config";

const OurStory = () => {
  useEffect(() => {
    onContactLocation();
  }, []);
  const [post_title, setPostTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [post_content, setPostContent] = useState([]);
  const onContactLocation = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("page", "our story");
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/single_blog`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setPostContent(response.data.data.post_content);
        setPostTitle(response.data.data.post_title);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Rollz Ice Cream Success Story- Toronto&#039;s Best Desserts Shop
        </title>
        <meta
          name="description"
          content="Rollz Ice Cream proudly offers refreshing vegan drinks, and popping soda available at all Rollz outlets across Canada. Don&#039;t miss this delicious treat."
        />
        <meta
          property="og:title"
          content="Rollz Ice Cream Success Story- Toronto&#039;s Best Desserts Shop"
        />
        <meta
          property="og:description"
          content="Rollz Ice Cream proudly offers refreshing vegan drinks, and popping soda available at all Rollz outlets across Canada. Don&#039;t miss this delicious treat."
        />
      </Helmet>
      {isLoading && <Loader />}
      <Navbar />
      <Banner />
      <div className="content-wrapper">
        <div className="container">
          <div className="contain-padi">
            <div className="Card card-xs">
              <div className="row align-items-center">
                <div className="col-sm-4">
                  <div className="side-img">
                    <div className="franchise-head">
                      <h1 style={{ textAlign: "left" }}>{post_title}</h1>
                      <div
                        className="border-head-bottom"
                        style={{ margin: "0" }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8 side-second">
                  <div className="card-text">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post_content,
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default OurStory;
