import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import "../../assets/css/Blog.css";
import ReactPaginate from "react-paginate";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Common/Loader";
import moment from "moment";
import { config } from "../../config";

const Blog = () => {
 
  const [isLoading, setLoading] = useState(false);
  const [data, setdata] = useState([]);

  useEffect(() => {
    onBlog();
  }, []);

  const onBlog = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", 1);
    formData.append("page", "blog");
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/blogs`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setdata(response.data.data);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };

  const targetDivRef = useRef(null);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event) => {
    const targetDiv = targetDivRef.current;
    targetDiv.scrollIntoView({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setItemOffset(newOffset);
  };

  return (
    <div ref={targetDivRef}>
      <Helmet>
        <title>Enjoy Yummy Ice Cream And Desserts Blog | Rollz Ice Cream</title>
        <meta
          name="description"
          content="Discover a treasure trove of blog content covering ice cream recipe, what is and how to make cheesecake, and much more. Feel free to explore more."
        />
        <meta
          property="og:title"
          content="Enjoy Yummy Ice Cream And Desserts Blog | Rollz Ice Cream"
        />
        <meta
          property="og:description"
          content="Discover a treasure trove of blog content covering ice cream recipe, what is and how to make cheesecake, and much more. Feel free to explore more."
        />
      </Helmet>
      {isLoading && <Loader />}
      <Navbar />
      <Banner />
      <div className="content-wrapper" >
        <div className="container" >
          <div className="contain-padi">
            <div className="row" >
              <div className="col-lg-8 col-sm-12 col-12 pe-4">
                <div>
                  {currentItems.map((obj, i) => {
                    return (
                      <div className="d-flex mb-5" id="column-xs" key={i}>
                        <div id="date_sec">
                          <Link
                            to={`/blog-detail/${obj.slug}`}
                            className="date_section"
                          >
                            <h1> {moment(obj.date).format("DD")}</h1>
                            <div>{moment(obj.date).format("MMM yy")}</div>
                          </Link>
                        </div>
                        <div>
                          <div className="bg-white">
                            <div>
                              <img
                                src={obj.post_img}
                                alt="Rollz Ice Cream"
                                className="w-100"
                              />
                            </div>
                            <div className="blog-content">
                              <div>
                                <Link
                                  to={`/blog-detail/${obj.slug}`}
                                >
                                  <h1>{obj.post_title}</h1>
                                </Link>
                              </div>
                              <div>
                                <p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: obj.post_content.slice(0, 390),
                                    }}
                                  />
                                </p>
                                <div>
                                  <Link
                                    to={`/blog-detail/${obj.slug}`}
                                    className="btn-read-more"
                                  >
                                    Read More
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="pagination-left">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                      breakClassName={"page-item"}
                      breakLinkClassName={"ant-pagination-item-link"}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"ant-pagination-item-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"ant-pagination-item-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"ant-pagination-item-link"}
                      activeClassName={"active"}
                    />
                    </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-12 col-12 ps-4">
                <div>
                  <div className="bd-recent-post">
                    <div>
                      <h2>Recent Posts</h2>
                    </div>
                    {data.slice(0, 5).map((obj, i) => {
                      return (
                        <div className="row sect-post" key={i}>
                          <div className="col-3 pl-0">
                          <Link to={`/blog-detail/${obj.slug}`}>
                            <img
                              alt="Blog"
                              src={obj.post_img}
                              className="w-100"
                            />
                            </Link>
                          </div>
                          <div className="col-9">
                            <h5> <Link to={`/blog-detail/${obj.slug}`} style={{color:"rgb(121, 121, 121)", textDecoration:"none"}}>{obj.post_title}</Link></h5>
                            <p>{moment(obj.date).format("DD MMM yy")} </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
