import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import Footer from "../Common/Footer";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Common/Loader";
import { Helmet } from "react-helmet";
import "../../assets/css/Contact.css";
import BgImg from "../../assets/images/IMG_9.webp"
import { config } from "../../config";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);


  // state for errors messages
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  });


  // form validations
  const validateForm = () => {
    const newErrors = {};

    if (!name) {
      newErrors.name = 'First name is required.';
    }

    if (!email) {
      newErrors.email = 'Email is required.';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      newErrors.email = 'Invalid email format.';
    }

    if (!phone) {
      newErrors.phone = 'Phone number is required.';
    } else if (phone.length > 10 || phone.length < 10) {
      newErrors.phone = 'Phone number must be of 10 digits.';
    }

    if (!subject) {
      newErrors.subject = 'Subject is required.';
    }

    if (!message) {
      newErrors.message = 'Message is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const onContactUs = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("message", message);
      formData.append("subject", message);
      try {
        const response = await axios({
          method: "post",
          url: `${config.apiUrl}/contact_us`,
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          swal({
            icon: "success",
            text: "Your Message send Successfully",
          }).then((data) => {
            setLoading(false);
            setName("");
            setPhone("");
            setEmail("");
            setSubject("");
            setMessage("");
          });
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    onContactLocation();
  }, []);
  
  const [address, setAddress] = useState("");
  const [admin_email, setAdminEmail] = useState('');
  const [admin_phone, setAdminPhone] = useState('');
  const onContactLocation = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem("location"));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/contact_details`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setAdminPhone(response.data.data[0].phone);
        setAdminEmail(response.data.data[0].email);
        setAddress(response.data.data[0].address);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact Us- Ice Cream and Desserts Shop in Canada</title>
        <meta
          name="description"
          content="Rollz Ice Cream is located at Ajax, Mississauga, Scarborough, Collingwood, Regina, Edmonton, Guelph, Vaughan, Windsor, Kitchener, Brampton, Brantford, Peterborough, and Halifax."
        />
        <meta
          property="og:title"
          content="Contact Us- Ice Cream and Desserts Shop in Canada"
        />
        <meta
          property="og:description"
          content="Rollz Ice Cream is located at Ajax, Mississauga, Scarborough, Collingwood, Regina, Edmonton, Guelph, Vaughan, Windsor, Kitchener, Brampton, Brantford, Peterborough, and Halifax."
        />
      </Helmet>
      {isLoading && <Loader />}
      <Navbar />
      <Banner />
      <div className="content-wrapper">
        <div className="container">
          <div className="contain-padi">
            <div className="franchise-head">
              <h1>Contact Us</h1>
              <div className="border-head-bottom"></div>
            </div>
            <div className="mt-5 pt-3">
              <div className="row align-items-center">
                <div className="col-md-5 pe-5 pe-xs-0">
                  <div
                    style={{
                      backgroundImage:
                        `url(${BgImg})`,
                      borderRadius: "3px",
                    }}
                  >
                    <div className="overlay-service">
                      <div className="contact-service-detail ">
                        <div>
                          <h1>Contact Details</h1>
                        </div>
                        <div className="mb-5">
                          <div className="d-flex">
                            <div className="contact-info-service text-center">
                              <i className="bi bi-telephone-inbound"></i>
                            </div>
                            <div>
                              <h4>Call Us</h4>
                              <p className="mb-0">
                                <a href={`tel:${admin_phone}`}>{admin_phone}</a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-5">
                          <div className="d-flex">
                            <div className="contact-info-service text-center">
                              <i className="bi bi-envelope-arrow-down-fill"></i>
                            </div>
                            <div>
                              <h4>Write to Us</h4>
                              <p className="mb-0">
                                <a href={`mailTo:${admin_email}`}>{admin_email}</a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="d-flex">
                            <div className="contact-info-service text-center">
                              <i className="bi bi-geo-alt-fill"></i>
                            </div>
                            <div>
                              <h4>Address</h4>
                              <p className="mb-0">{address}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-7" id="get-in-touch">
                  <div className=" mb-4  ">
                    <h1 className="" style={{ fontSize: "38px" }}>
                      Get In Touch
                    </h1>
                    <h5 style={{ fontSize: "18px" }}>Fill the Form</h5>
                  </div>
                  <form onSubmit={onContactUs}>
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          className="form-rollz mt-md-0"
                          placeholder="Enter Name"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {
                          errors.name && <span className="text-danger">{errors.name}</span>
                        }
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="form-rollz mt-md-0"
                          placeholder="Enter Email Address"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {
                          errors.email && <span className="text-danger">{errors.email}</span>
                        }
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="form-rollz"
                          placeholder="Enter Phone Number"
                          type="text"
                          onKeyPress={(event) => {
                            if (
                              !/[0-9.]/.test(event.key)
                            ) {
                              event.preventDefault();
                            }
                          }}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                        {
                          errors.phone && <span className="text-danger">{errors.phone}</span>
                        }
                      </div>
                      <div className="col-sm-6">
                        <input
                          className="form-rollz"
                          placeholder="Enter subject"
                          type="text"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        {
                          errors.subject && <span className="text-danger">{errors.subject}</span>
                        }
                      </div>
                      <div className="col-sm-12">
                        <textarea
                          className="form-rollz"
                          placeholder="Your Message"
                          rows="7"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        {
                          errors.message && <span className="text-danger">{errors.message}</span>
                        }
                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <button className="btn btn-submit">
                        Send Your Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contactus;
