import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "../Common/Footer";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import swal from "sweetalert";
import Loader from "../Common/Loader";
import "../../assets/css/SubMenu.css";
import rollzMenu from "../../assets/images/Menu-Without-pint-and-cake.pdf";
import { config } from "../../config";

const SubMenu = () => {
  const location = useLocation();
  const main_url = location.pathname.split("/").slice(-1);
  const [locationModal, setLocationModal] = useState(false);
  const [show, setShow] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [data, setdata] = useState([]);
  const params = useParams();
  useEffect(() => {
    onMenuCategory();
    onMenuItem();
  }, []);
  // Location UseEffect

  useEffect(() => {
    onMenuItem();
  }, [location]);
  const onMenuCategory = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem('location'));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/product_category`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setCategoryList(response.data.data);

        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const [allData, setAllData] = useState([]);
  const onMenuItem = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem('location'));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/products`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setdata(response.data.data);
        setAllData(response.data.data);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  // console.log(allData)

  const [menu_id, setMenuId] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (main_url == "rolled-ice-cream") {
      const Rollz = allData.filter((res) => res.subcategory == 1);
      // console.log(allData)
      const updatedArray = Rollz.map((item) => {
        if (item.subcategory == 1) {
          return { ...item, menu_slug: "rolled-ice-cream" };
        }
        return item;
      });
      setdata(updatedArray);
    }
  }, [allData]);

  const [phone, setPhone] = useState("");
  const [person_name, setPersonName] = useState("");
  const onLocationMenu = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("person_name", person_name);
    formData.append("type", 0);
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/download_menu`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        swal({
          icon: "success",
          text: "Your Menu Downloaded Successfully",
        }).then((data) => {
          setLoading(false);
          setPhone("");
          setPersonName("");
          const anchor = document.createElement("a");
          const fileUrl = rollzMenu;
          anchor.href = fileUrl;

          anchor.download = "Menu-Without-pint-and-cake.pdf";
          document.body.appendChild(anchor);

          anchor.click();

          document.body.removeChild(anchor);
        });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }


  // console.log(data)


  return (
    <div>
      <Helmet>
        <title>
        {main_url == "rolled-ice-cream" ? "Best Rolly Ice Cream Near You - Halal Ice Cream - Desi Ice Cream" : main_url == "milkshakes" ? "Best Ice Cream Milkshakes Near Me in Toronto | Rollz Ice Cream" : main_url =="waffles" ? "Best Waffles Makers Near Me - Strawberry & Chocolate Waffles" : main_url == "falooda" ? "Best Faloodas Near Me in Canada | Rollz Ice Cream" : main_url == "cheesecake" ? "Best Cheesecake Factory Near Me in Canada | Rollz Ice Cream" : main_url == "popping-soda" ? "Refreshing Vegan Drinks & Popping Soda Near Me in Toronto" : main_url == "ice-cream-cake" ? "Delicious Ice Cream Cake Near You in Toronto" : main_url == "ice-cream-pints" ? "Best Ice Cream Pints Near Me in Toronto | Rollz Ice Cream": "Best Rolly Ice Cream Near You - Halal Ice Cream - Desi Ice Cream" }

        </title>
        <meta
          name="description"
          content={main_url == "rolled-ice-cream" ? "Rollz is serving mouthwatering treats, from tawa ice cream with desi style to cheesecake delights. You will also find kulfi, fresh fruits rolled ice cream located near you." : main_url == "milkshakes" ? "Rollz offer a delicious selection of shakes, available in different flavours. We serves milkshakes at Ajax, Brantford, Regina, Vaughan, etc." : main_url =="waffles" ? "Indulge in the delightful flavours of strawberry and chocolate waffles at Rollz Ice Cream. We are serving the best waffles in town." : main_url == "falooda" ? "Indulge in heavenly Faloodas at Rollz. Delight in refreshing flavors, toppings, and textures. Experience dessert perfection in Toronto today!" : main_url == "cheesecake" ? "Discover the best cheesecake near you in Toronto, Canada. Our blog section highlights on what is cheesecake, cheesecake receipe and how to make cheesecake." : main_url == "popping-soda" ? "Quench your thirst with refreshing vegan drinks, including Popping Soda. Find the best popping soda in Toronto and nearby locations from Rollz Ice Cream." : main_url == "ice-cream-cake" ? "Indulge in the finest ice cream cake at Rollz Ice Cream. Discover the perfect treat near you in Toronto. Experience the joy of our ice cream cakes today!" : main_url == "ice-cream-pints" ? "Discover the finest ice cream pints in Toronto at Rollz Ice Cream. Indulge in a variety of flavors. Your perfect dessert treat is just a visit away!": "Rollz is serving mouthwatering treats, from tawa ice cream with desi style to cheesecake delights. You will also find kulfi, fresh fruits rolled ice cream located near you." }
        />
        <meta
          property="og:title"
          content="Best Rolly Ice Cream Near You - Halal Ice Cream - Desi Ice Cream"
        />
        <meta
          property="og:description"
          content="Rollz is serving mouthwatering treats, from tawa ice cream with desi style to cheesecake delights. You will also find kulfi, fresh fruits rolled ice cream located near you."
        />
      </Helmet>
      {isLoading && <Loader />}
      <Navbar />
      <Banner />
      <div>
        <div className="content-wrapper">
          <div className="container">
            <div className="contain-padi">
              <div className="row">
                <div className="sidebar-menu">
                  <div className="d-none d-xs-block">
                    <button
                      className="navbar-toggler ms-2 mb-3"
                      onClick={() => openNav()}
                    >
                      <span className="navbar-toggler-icon text-light fs-2">
                        {" "}
                        ☰
                      </span>
                    </button>
                  </div>
                  <div className="sidebar-box web" id="rolled-ice-cream">
                    {categoryList.slice(0, 8).map((obj, i) => {
                      if(obj.is_active == 1){
                      return (
                        <div key={i}>
                          <p
                            className={
                              obj.slug == main_url ? "btn-active-menu" : ""
                            }
                          >
                            <Link to={`/${obj.slug}`}>
                              {obj.menu_name}
                            </Link>
                          </p>
                          {main_url == "rolled-ice-cream" ? (
                            <>
                              {categoryList.map((res, i) => {
                                if (obj.slug == "rolled-ice-cream") {
                                  if (res.subcategory == 1) {
                                    return (
                                      <ul className="mb-4" key={i}>
                                        <li>
                                          <p
                                            className={
                                              res.id == menu_id
                                                ? "side-active"
                                                : ""
                                            }
                                          >
                                            <a
                                              onClick={() => {
                                                if (
                                                  main_url != "rolled-ice-cream"
                                                ) {
                                                  navigate(
                                                    "/rolled-ice-cream"
                                                  );
                                                  setdata(
                                                    allData.filter(
                                                      (obj) =>
                                                        obj.menu_id == res.id
                                                    )
                                                  );
                                                  setMenuId(res.id);
                                                } else {
                                                  // console.log(allData , res)
                                                  setdata(
                                                    allData.filter(
                                                      (obj) =>
                                                        obj.menu_id == res.id
                                                    )
                                                  );

                                                  setMenuId(res.id);
                                                }
                                              }}
                                              className="cursor-point"
                                            >
                                              {res.menu_name}
                                            </a>
                                          </p>
                                        </li>
                                      </ul>
                                    );
                                  }
                                }
                              })}
                            </>
                          ) : null}
                        </div>
                      );
                    }
                    })}
                  </div>
                  <div id="mySidenav" className="sidenav">
                    <div className="sidebar-box " id="rolled-ice-cream">
                      <p
                        className="closebtn curson-pointer" style={{cursor:'pointer'}}
                        onClick={() => closeNav()}
                      >
                        &times;
                      </p>
                      {categoryList.slice(0, 8).map((obj, i) => {
                        return (
                          <div key={i}>
                            <p
                              className={
                                obj.slug == main_url ? "btn-active-menu" : ""
                              }
                            >
                              <Link to={`/${obj.slug}`}>
                                {obj.menu_name}
                              </Link>
                            </p>
                            {main_url == "rolled-ice-cream" ? (
                              <>
                                {categoryList.map((res, i) => {

                                  if (obj.slug == "rolled-ice-cream") {
                                    if (res.subcategory == 1) {

                                      return (
                                        <ul className="mb-4" key={i}>
                                          <li>
                                            <p
                                              className={
                                                res.id == menu_id
                                                  ? "side-active"
                                                  : ""
                                              }
                                            >
                                              <a
                                                onClick={() => {
                                                  closeNav();
                                                  if (
                                                    main_url !=
                                                    "rolled-ice-cream"
                                                  ) {
                                                    navigate(
                                                      "/rolled-ice-cream"
                                                    );
                                                    setdata(
                                                      allData.filter(
                                                        (obj) =>
                                                          obj.menu_id == res.id
                                                      )
                                                    );
                                                    setMenuId(res.id);
                                                  } else {
                                                    setdata(
                                                      allData.filter(
                                                        (obj) =>
                                                          obj.menu_id == res.id
                                                      )
                                                    );
                                                    setMenuId(res.id);
                                                    closeNav();
                                                  }
                                                }}
                                                className="cursor-point"
                                              >
                                                {res.menu_name}
                                              </a>
                                            </p>
                                          </li>
                                        </ul>
                                      );
                                    }
                                  }
                                })}
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="product-list">
                  <div>
                    <div className="bg-menu-image submenu-type">
                      <div className="bd-menu-box">
                        <div className="row">
                          {data.map((obj, i) => {
                            if (main_url == "rolled-ice-cream") {
                              if (menu_id == false) {
                                if (obj.menu_slug == main_url) {
                                  return (
                                    <div className="col-lg-4 mb-5" key={i}>
                                      <div className="menu-box">
                                        <img
                                          src={obj.img}
                                          alt="Rollz Ice Cream"
                                          className=""
                                        />
                                        <h5>{obj.menu_item_name}</h5>
                                      </div>
                                    </div>
                                  );
                                }
                              } else {
                                // console.log(obj)
                                return (
                                  <div className="col-lg-4 mb-5" key={i}>
                                    <div className="menu-box">
                                      <img
                                        src={obj.img}
                                        alt="Rollz Ice Cream"
                                        className=""
                                      />
                                      <h5>{obj.menu_item_name}</h5>
                                    </div>
                                  </div>
                                );
                              }
                            } else {
                              if (obj.menu_slug == main_url) {
                                return (
                                  <div className="col-lg-4 mb-5" key={i}>
                                    <div className="menu-box">
                                      <img
                                        src={obj.img}
                                        alt="Rollz Ice Cream"
                                        className=""
                                      />
                                      <h5>{obj.menu_item_name}</h5>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <form onSubmit={onLocationMenu}>
                        <div className="cta-whatsapp">
                          <div className="text-center whatsapp-menu">
                            <h5>
                              Get Menu On Whatsapp Of{" "}
                            </h5>
                          </div>
                          <div className=" row align-items-center">
                            <div className="col-sm-5 form-name">
                              <input
                                className="form-rollz mt-md-0"
                                placeholder="Enter Name"
                                type="text"
                                value={person_name}
                                onChange={(e) => setPersonName(e.target.value)}
                                required
                              />
                            </div>
                            <div className="col-sm-5 form-name">
                              <input
                                className="form-rollz mt-md-0"
                                placeholder="Enter Phone Number"
                                type="text"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                                maxLength={12}
                              onKeyPress={(event) => {
                                if (
                                  !/[0-9.]/.test(event.key)
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              />
                            </div>
                            <div className="col-sm-2">
                              <div>
                                <button className="download-whatsapp">
                                  Get Menu
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="bd-content-small mt-5">
                      <div>
                        <h3>Halal Ice Cream </h3>
                        <p>
                          If you’re looking for Halal ice cream, look no further
                          – Rollz Ice Cream’s got your back! We know our
                          customers come from a variety of backgrounds with food
                          specifications. We have one of the best rolled ice
                          cream that is halal ice cream with flavors from the
                          fruity Passion Pinnago to the Mava Kulfi. Rollz Ice
                          cream uses the best and freshest ingredients in our
                          rolled ice creams that is sure to leave you satisfied,
                          but also wanting for more. If you’re craving for a
                          sweet treat or simply wanting a sweet snack, we have
                          rolled ice cream for you to try.
                        </p>
                        <p>
                          What are you waiting for? Visit our stores for the
                          best Halal ice cream near you! We are located in
                          Scarborough, Brampton, and Ajax.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />


      <Modal
        show={locationModal}
        onHide={() => setLocationModal(false)}
        className="video-modal location-modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="video-title">Our Locations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <h6
              className="mb-3"
              style={{ color: "#0e72ba", fontWeight: "550" }}
            >
              Pick your location !
            </h6>
            <span className="badge badge-primary">
              {" "}
              <p
              className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Ajax
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Mississauga
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Scarborough
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Collingwood
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Regina
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Edmonton
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Guelph
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Vaughan
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Windsor
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Kitchener
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Scarborough
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                North Park Drive, Brampton
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Brantford
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Peterborough
              </p>
            </span>
            <span className="badge badge-primary">
              {" "}
              <p
                 className="mb-0"
                onClick={() => setLocationModal(false)}
                style={{ cursor: "pointer" }}
              >
                Halifax
              </p>
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SubMenu;
