import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import Footer from "../Common/Footer";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../Common/Loader";
import { Link } from "react-router-dom";
import { config } from "../../config";

const Location = () => {
  const [data, setdata] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    onLocation();
  }, []);

  const onLocation = async (e) => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${config.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setdata(response.data.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };


  return (
    <div>
      <Helmet>
        <title>Rollz Ice Cream locations in Canada</title>
        <meta
          name="description"
          content="Find out Rollz at multiple locations, including Brampton, Ajax, Brantford, Collingwood, Edmonton, Guelph, Kitchener, Regina, Scarborough, Vaughan, Windsor, Mississauga, etc."
        />
        <meta
          property="og:title"
          content="Rollz Ice Cream locations in Canada"
        />
        <meta
          property="og:description"
          content="Find out Rollz at multiple locations, including Brampton, Ajax, Brantford, Collingwood, Edmonton, Guelph, Kitchener, Regina, Scarborough, Vaughan, Windsor, Mississauga, etc."
        />
      </Helmet>
      {isLoading && <Loader />}
      <Navbar />
      <Banner />

      <div className="content-wrapper">
        <div className="container">
          <div className="contain-padi">
            <div className="franchise-head">
              <h1>Locations</h1>
              <div className="border-head-bottom"></div>
            </div>
            <div>
              <div className="row">
                {data.map((obj, i) => {

                  return (
                    <div className="col-md-3 mb-5" key={i}>
                      <div className="location-card">
                        <div>
                          <iframe
                            src={obj.map_link}
                            style={{
                              border: "0",
                              width: "100%",
                              height: "180px",
                            }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                        <div className="location-card-content">
                          <h2>{obj.location_name}</h2>
                          <p>
                            <i className="bi bi-geo-alt-fill"></i>
                            {obj.location_address}
                          </p>
                          <p className="call">
                            <i className="bi bi-telephone-inbound-fill"></i>
                            {obj.phone != null ? obj.phone : "Not Available"}
                          </p>
                          <div className="mt-3 d-flex position-relative">
                            <div className="me-2">
                              <a
                                href={obj.review_link}
                                className="btn-arraow"
                                target="_blank"
                              >
                                <i className="bi bi-arrow-90deg-right"></i>
                              </a>
                            </div>
                            <div className="pl-0-location">
                              <Link
                                to={obj.review_link}
                                target="_blank"
                                className="btn-arraow-loc"
                              >
                                Write Review for Location
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Location;
