import React from "react";
import "../../assets/css/Footer.css";
import Logo from "../../assets/images/logo.webp";
import { Link } from "react-router-dom";
import Facebook from "../../assets/images/facebook-social-media.png";
import Instagram from "../../assets/images/Instagram_icon.png-300x300.webp";
import TikTok from "../../assets/images/Round-TikTok-Logo-1024x1024-1-300x300.png";
import YouTube from "../../assets/images/youtube.png";


const Footer = () => {
  return (
    <div>
      <div className="bd-footer ">
        <div className="triangle-top"></div>
        <div className="container">
          <div className="mt-3">
            <div className="row">
              <div className="col-sm-4">
                <div className="mb-3 footer-img-xs-cener">
                  <img src={Logo} className="footer-img" alt="Rollz Ice Cream" />
                </div>
                <div className="rollz-footer">
                  {/* <h1>Rollz Ice Cream</h1> */}
                  <p>We specialize in mouth-watering rolled ice cream.</p>
                </div>
                <div className="rollz-head">
                  <p style={{fontSize:"20px", fontWeight:"600"}}>Head Office </p>
                  <p>
                    5746 Finch Ave E, Unit 3, Scarborough, ON M1B 5J9, Canada
                  </p>
                </div>
                <div className="rollz-info">
                  <div>
                    <i className="bi bi-envelope-at-fill"></i> :
                    <a href="mailto:info@rollzicecream.ca" className="ms-2">
                      info@rollzicecream.ca
                    </a>
                  </div>
                  <div>
                    <i className="bi bi-telephone-inbound-fill"> </i>:
                    <a href="callto:416-293-2223" className="ms-2">
                      416-293-2223
                    </a>
                  </div>
                  <div>
                    <a href="https://order.tikme.co/" target="_blank">
                    <i className="bi bi-cart-check-fill"></i> : Order Now
                    </a>
                  </div>
                </div>
                <div className="d-flex mt-5 footer-social">
                  <div className="me-4">
                    <Link
                      to={`https://www.facebook.com/rollzicecream`}
                      target="_blank"
                    >
                      <img
                        src={Facebook}
                        alt="Rollz Ice Cream"
                        width="50"
                        height="50"
                      />
                    </Link>
                  </div>
                  <div className="me-4">
                    <Link
                      to={`https://www.instagram.com/rollzicecreams/`}
                      target="_blank"
                    >
                      <img
                        src={Instagram}
                        alt="Rollz Ice Cream"
                        width="50"
                        height="50"
                      />
                    </Link>
                  </div>
                  <div className="me-4">
                    <Link
                      to={`https://www.tiktok.com/@rollzicecream`}
                      target="_blank"
                    >
                      <img
                        src={TikTok}
                        alt="Rollz Ice Cream"
                        width="50"
                        height="50"
                      />
                    </Link>
                  </div>
                  <div className="me-4">
                    <Link
                      to={`https://www.youtube.com/@rollzicecream`}
                      target="_blank"
                    >
                      <img
                        src={YouTube}
                        alt="Rollz Ice Cream"
                        width="50"
                        height="50"
                      />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="bd-menus">
                  <h2 className="text-white mb-3 ps-4">Quick Links</h2>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/menu">Menu</Link>
                    </li>
                    <li><Link to="/locations">Locations</Link></li>
                    <li>
                      <Link to="/franchise">Franchise</Link>
                    </li>
                    <li>
                      <Link to="/our-story">Our Story</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="rollz-head">
                  <h2 className="text-white mb-3">Locations</h2>
                  <p>
                    <a href="https://goo.gl/maps/JTMwjAYByrXtFxmQ7" target="_blank" style={{color:"white" , textDecoration:"none"}}>
                    Vaughan: Unit 1, 3883 Highway 7, Vaughan, Ontario, L4L 6C1
                    </a>
                  </p>
                  <p>
                  <a href="https://goo.gl/maps/GpmfwZwa9ZZkpNyeA"  target="_blank"  style={{color:"white" , textDecoration:"none"}}>
                    Kitchener: Unit 15, 509 Wilson Ave, Kitchener, ON N2C 2M4
                    </a>
                  </p>
                  <p>
                    <a href="https://g.page/rollzicecream?share"  target="_blank"  style={{color:"white" , textDecoration:"none"}}>
                    Scarborough: 5631 Steeles Ave East, Unit 6, Scarborough ON
                    M1V 5P6
                    </a>
                  </p>

                  <div className="mt-4">
                    <Link
                      to="/locations"
                      className="btn-view-location"
                    >
                      {" "}
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
