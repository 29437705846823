import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import Footer from "../Common/Footer";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import Loader from "../Common/Loader";
import axios from "axios";
import { config } from "../../config";
import Logo from "../../assets/images/logo.webp";
import { useLocation, useNavigate } from "react-router-dom";

const ThankYouMenu = () => {

    const navigate = useNavigate()

    const locationData = useLocation()

    useEffect(() => {
        if (!locationData?.state?.message) {
            navigate("/menu")
        }
    }, [])

    return (
        <div>
            <Helmet>
                <title>
                    Rollz Ice Cream Success Story- Toronto&#039;s Best Desserts Shop
                </title>
                <meta
                    name="description"
                    content="Rollz Ice Cream proudly offers refreshing vegan drinks, and popping soda available at all Rollz outlets across Canada. Don&#039;t miss this delicious treat."
                />
                <meta
                    property="og:title"
                    content="Rollz Ice Cream Success Story- Toronto&#039;s Best Desserts Shop"
                />
                <meta
                    property="og:description"
                    content="Rollz Ice Cream proudly offers refreshing vegan drinks, and popping soda available at all Rollz outlets across Canada. Don&#039;t miss this delicious treat."
                />
            </Helmet>
            {/* {isLoading && <Loader />} */}
            <Navbar />
            {/* <Banner /> */}
            <div className="content-wrapper">
                <div className="container">
                    <div className="contain-padi">
                        <div className="Card card-xs">
                            <div className="franchise-head">
                                <h1>Thank You </h1>
                                <div className="border-head-bottom"></div>
                            </div>
                            <div className=" d-flex align-items-center">
                                <img
                                    className="middle-img img-fluid me-5"
                                    src={Logo}
                                    alt="Rollz Ice Cream"
                                />
                                <div>
                                    <h5>{locationData?.state?.message} </h5>
                                    <p >Thank you for downloading our menu! We hope you find it helpful and that it inspires you to visit us soon. If you have any questions or need further assistance, feel free to reach out to us. Enjoy exploring our offerings!</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ThankYouMenu;
