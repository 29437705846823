// import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import Index from "./components/Home/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Franchise from "./components/Home/Franchise";
import Blog from "./components/Home/Blog";
import BlogDetail from "./components/Home/BlogDetail";
import Menu from "./components/Home/Menu";
import OurStory from "./components/Home/OurStory";
import Contactus from "./components/Home/Contactus";
import Location from "./components/Home/Location";
import SubMenu from "./components/Home/SubMenu";
import PrivacyPolicy from "./components/Home/PrivacyPolicy";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import TikTok from "./assets/images/Tiktok.png"
import PageNotFound from "./components/Common/PageNotFound";
import { config } from "./config";
import ThankYouMenu from "./components/Home/ThankYouMenu";

export const home_routes = [
  "/ajax",
  "/mississauga",
  "/scarborough",
  "/collingwood",
  "/regina",
  "/edmonton",
  "/guelph",
  "/vaughan",
  "/windsor",
  "/kitchener",
  "/brantford",
  "/calgary",
  "/north-park-drive-brampton",
  "/peterborough",
  "/halifax",
  "/waterloo",
  "/lebovic-scarborough",
  "/meadows",
  "/kingston"
]

function App() {
  const [call, setCall] = useState(false);
  const [location_name_change, setLocationNameChange] = useState("Ajax")

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get("https://ipapi.co/json");
      const formData = new FormData();
      formData.append("location_name", res.data.city);
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/location_ip`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      localStorage.setItem("location", response.data.location);
      setCall(true);
    };
    if (localStorage.getItem("location") == null) {
      getData();
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path="/" element={
              <Index call={call} setLocationNameChange={setLocationNameChange}
                location_name_change={location_name_change} />
            }
            />

            {
              home_routes.map((route) => {
                return <Route key={route} path={route} element={
                  <Index call={call} setLocationNameChange={setLocationNameChange} location_name_change={location_name_change}
                  />} />
              })
            }
            <Route

              path="/franchise"
              element={<Franchise />}
            />
            {/* <Route
            path="/blog-detail"
            element={<BlogDetail />}
          /> */}

            <Route path="/menu" element={<Menu />} />
            <Route path="/our-story" element={<OurStory />} />
            <Route

              path="/contact-us"
              element={<Contactus />}
            />
            <Route

              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route path="/locations" element={<Location />} />

            <Route path="/milkshakes" element={<SubMenu />} />
            <Route path="/waffles" element={<SubMenu />} />
            <Route path="/faloodas" element={<SubMenu />} />
            <Route path="/cheesecake" element={<SubMenu />} />
            <Route

              path="/rolled-ice-cream"
              element={<SubMenu />}
            />
            <Route

              path="/popping-soda"
              element={<SubMenu />}
            />
            <Route

              path="/ice-cream-cake"
              element={<SubMenu />}
            />
            <Route

              path="/ice-cream-pints"
              element={<SubMenu />}
            />
            <Route

              path="/premium-flavours"
              element={<SubMenu />}
            />

            <Route path="/blog" element={<Blog />} />
            <Route path="/thankyou" element={<ThankYouMenu />} />
            {/* <Route path="*" element={
              <Index call={call} setLocationNameChange={setLocationNameChange}
                location_name_change={location_name_change} />
            } /> */}
            <Route

              path="/blog-detail/:slug"
              element={<BlogDetail />}
            />

            {/* <Route
            path="*"
            element={<PageNotFound />}
          /> */}

          </Routes>
        </Fragment>
      </BrowserRouter>

      <div>
        <div className="row social-side-link">
          <a className=" insta" href="https://www.instagram.com/rollzicecreams/" aria-label="Social Media Link" target="_blank">
            <i className="bi bi-instagram"></i>
          </a>
          <a className=" tiktok" aria-label="Social Media Link" href="https://www.tiktok.com/@rollzicecream" target="_blank">
            <img src={TikTok} className="" alt="social Icon" width='30' height='30' />
          </a>
        </div>
      </div>
    </>
  );
}

export default App;
