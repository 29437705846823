import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import swal from "sweetalert";
import Loader from "./Loader";

const SliderSection = ({ data, sliderTitle, sliderDesc }) => {
  const sliderBlogRef = useRef();
  const blogsSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: false,
    centerMode: true,
    arrows: false,
    autoplaySpeed: 8000,
    centerPadding: "0", // Remove padding around the center slide
    responsive: [
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="slider-section ">
        <div className="container" id="my-cont">
          <div className="banner-slick">
            <div className="slider-text">
              <h1>{sliderTitle}</h1>
              <h2>{sliderDesc}</h2>
            </div>
            <div className="row slick-image pt-4">
              <p
                className="style-btn-slider col-5 me-3 slick-left"
                onClick={() => sliderBlogRef.current.slickPrev()}
                style={{ cursor: "pointer" }}
              >
                <i className="bi bi-chevron-left"></i>
              </p>
              <p
                style={{ cursor: "pointer" }}
                className="style-btn-slider col-5 slick-right"
                onClick={() => sliderBlogRef.current.slickNext()}
              >
                <i className="bi bi-chevron-right"></i>
              </p>

              <Slider ref={sliderBlogRef} {...blogsSettings}>
                {data?.map((obj, index) => {
                  return (
                    <div
                      className={`slider-item ${
                        index === 1 ? "center-item" : ""
                      }`}
                      key={index}
                    >
                      <div>
                        <img src={obj.image} className="w-100" alt="Rollz Ice Cream" />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      <div className="cdoslick-bottom-bg"></div>
    </div>
  );
};

export default SliderSection;
