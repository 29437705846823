import React from "react";
import BannerImg from "../../assets/images/cream-bg-02.png"

const Banner = () => {
  return (
    <div>
      <div className="bg-blue">
        <div className="banner-img">
        </div>
      </div>
    </div>
  );
};

export default Banner;
