import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import Footer from "../Common/Footer";
import { Helmet } from "react-helmet";
import swal from "sweetalert";
import axios from "axios";
import "../../assets/css/Privacy-policy.css";
import Loader from "../Common/Loader";
import { config } from "../../config";

const PrivacyPolicy = () => {
  useEffect(() => {
    onPolicy();
  }, []);
  const [post_title, setPostTitle] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [post_content, setPostContent] = useState([]);
  const onPolicy = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("page", "privacy policy");
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/single_blog`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setPostContent(response.data.data.post_content);
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <div>
      {isLoading && (<Loader/>)}
      <Helmet>
        <title>Privacy Policy - Rollz Ice Cream</title>
        <meta property="og:title" content="Privacy Policy - Rollz Ice Cream" />
        <meta
          property="og:description"
          content="Privacy Policy For Rollz Icecream Rollz Icecream informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. We use your data to provide and improve the Service. By using the Service, you agree to the collection and [&hellip;]"
        />
      </Helmet>
      <Navbar />
      <Banner />
      <div className="content-wrapper">
        <div className="container">
          <div className="contain-padi">
            <div className="franchise-head">
              <h1>Privacy Policy</h1>
              <div className="border-head-bottom"></div>
            </div>
            <div>
              <div className="Card card-xs">
                <div className="col-12 side-second" id="side-sec">
                  <div className="card-text ">
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post_content,
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
