import React, { useEffect, useState } from "react";
import "../../assets/css/Navbar.css";
import Logo from "../../assets/images/logo.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { home_routes } from "../../App";
import { config } from "../../config";

const Navbar = ({ call, setLocationNameChange }) => {
  const [locationModal, setLocationModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const location = useLocation();
  const mainLink = location.pathname?.split("/")?.slice(-1);

  useEffect(() => {
    onLocation();
    if (localStorage.getItem("location") != null) {
      if (category?.length == 0) {
        onMenuCategory()
      }
    }
  }, [call]);

  const [data, setData] = useState([]);

  const onLocation = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `${config.apiUrl}/locations`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const [category, setCategoryList] = useState([])

  const navigate = useNavigate()

  const onMenuCategory = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem('location'));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/product_category`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setCategoryList(response.data.data)
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="nav-fixed">
      <nav className="navbar navbar-expand-lg navbar-light bg-nav">
        <div className="container">
          <div className="withoutcollapse d-none"></div>
          <ul className="rs-logob withoutcollapse d-none text-center ms-3">
            <li className="nav-link text-center">
              <div className="location-modal" id="location-modal-2">
                <p
                  onClick={() => setLocationModal(true)}
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-geo-alt-fill p-1"></i>  {localStorage.getItem('location_name') ? localStorage.getItem('location_name') : 'Ajax'}                </p>
              </div>{" "}
            </li>
            <li className="nav-link text-center">
              <Link
                to="/"
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  className="middle-img img-fluid"
                  src={Logo}
                  alt="Rollz Ice Cream"
                />
              </Link>
            </li>
          </ul>
          <div>
            <button
              className="navbar-toggler ms-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
          {/* collapse */}
          <div
            className="collapse navbar-collapse justify-space-between"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto order-xs-2">
              <li className="nav-item">
                <Link
                  to="/"
                  className={
                    mainLink == ""
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Home
                </Link>
              </li>
              <li className="nav-item menu-item">
                <Link
                  className={
                    mainLink == "menu" ? "nav-link active submenu" : "nav-link"
                  }
                  to="/menu"
                >
                  Menu
                </Link>
                <ul className="nav-sublink">
                  {category?.slice(0, 8)?.map((obj, i) => {
                    if (obj.is_active == 1) {
                      return (
                        <li key={i}>
                          <Link to={`/${obj.slug}`}>
                            <i className="bi bi-arrow-right icon-right"></i>
                            {obj.menu_name}
                          </Link>
                        </li>
                      )
                    }
                  })}
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  to="/locations"
                  className={
                    mainLink == "locations" ? "nav-link active" : "nav-link"
                  }
                  href="#"
                >
                  Locations
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    mainLink == "blog" ? "nav-link active" : "nav-link"
                  }
                  to="/blog"
                >
                  Blog
                </Link>
              </li>
            </ul>
            <div id="mainl" className="order-xs-1">
              <div className="rs-logob">
                <Link to="/">
                  <img
                    className="middle-img"
                    src={Logo}
                    alt="Rollz Ice Cream"
                  />
                </Link>
              </div>
            </div>
            <ul className="navbar-nav mr-auto order-xs-3">
              <li className="nav-item active">
                <Link
                  className={
                    mainLink == "franchise" ? "nav-link active" : "nav-link"
                  }
                  to="/franchise"
                >
                  Franchise
                </Link>
              </li>
              <li className="nav-item active">
                <Link
                  className={
                    mainLink == "our-story" ? "nav-link active" : "nav-link"
                  }
                  to="/our-story"
                >
                  Our Story
                </Link>
              </li>
              <li className="nav-item active">
                <div className="location-modal" id="loca-m-oncoll">
                  <p
                    onClick={() => setLocationModal(true)}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-geo-alt-fill p-1"></i>
                    {localStorage.getItem('location_name') ? localStorage.getItem('location_name') : 'Ajax'}
                  </p>
                </div>
                <Link
                  className={
                    mainLink == "contact-us" ? "nav-link active" : "nav-link"
                  }
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="triangle">&nbsp;</div>
      <Modal
        show={locationModal}
        onHide={() => setLocationModal(false)}
        className="video-modal location-modal"
        centered
        size="lg"
        id="video-modal-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title className="video-title">Our Locations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <h6
              className="mb-3"
              style={{ color: "#0e72ba", fontWeight: "550" }}
            >
              Pick your location !
            </h6>
            {data.map((obj, i) => {
              return (
                <span className="badge badge-primary" key={i}>
                  <p
                    onClick={() => {
                      setLocationModal(false);
                      setDisplayText(obj.location_name);
                      localStorage.setItem('location', obj.id)
                      localStorage.setItem('location_name', obj.location_name)
                      localStorage.setItem('change', true)

                      setLocationNameChange && setLocationNameChange(obj.location_name)

                      window.location.href = '/'

                      // if (
                      //   home_routes.filter((item) =>
                      //     item == location.pathname).length || location.pathname === "/rollzicecream-react/") {

                      //   switch (localStorage.getItem('location_name')) {
                      //     case "Ajax": navigate("/ajax"); break;
                      //     case "Mississauga": navigate("/mississauga"); break;
                      //     case "Scarborough": navigate("/scarborough"); break;
                      //     case "Collingwood": navigate("/collingwood"); break;
                      //     case "Regina": navigate("/regina"); break;
                      //     case "Edmonton": navigate("/edmonton"); break;
                      //     case "Guelph": navigate("/guelph"); break;
                      //     case "Vaughan": navigate("/vaughan"); break;
                      //     case "Windsor": navigate("/windsor"); break;
                      //     case "Kitchener": navigate("/kitchener"); break;
                      //     case "Brantford": navigate("/brantford"); break;
                      //     case "Calgary (Coming Soon)": navigate("/calgary"); break;
                      //     case "North Park Drive, Brampton": navigate("/north-park-drive-brampton"); break;
                      //     case "Halifax": navigate("/halifax"); break;
                      //     case "Peterborough": navigate("/peterborough"); break;
                      //     case "Waterloo (Coming Soon)": navigate("/waterloo"); break;

                      //     default: navigate("/")
                      //   }
                      // }
                    }}
                    style={{ cursor: "pointer" }}
                    className="mb-0"
                  >
                    {obj.location_name}
                  </p>
                </span>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Navbar;
