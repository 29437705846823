import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Banner from "../Common/Banner";
import "../../assets/css/Menu.css";
import Footer from "../Common/Footer";
import Facebook from "../../assets/images/facebook-social-media.png";
import Instagram from "../../assets/images/Instagram_icon.png-300x300.webp";
import TikTok from "../../assets/images/Round-TikTok-Logo-1024x1024-1-300x300.png";
import YouTube from "../../assets/images/youtube.png";
import { Helmet } from "react-helmet";
import commonMenu from "../../assets/images/Common-menu-.pdf";
import rollzMenu from "../../assets/images/Menu-Without-pint-and-cake.pdf";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";
import Loader from "../Common/Loader";
import { config } from "../../config";

const Menu = () => {

  const [isLoading, setLoading] = useState(false);
  const [location, setLocation] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const navigate = useNavigate()

  useEffect(() => {
    onMenuCategory();
  }, []);
  const onMenuCategory = async (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("location", localStorage.getItem('location'));
    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/product_category`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setCategoryList(response.data.data)
        setLoading(false);
      });
    } catch (error) {
      swal({
        icon: "error",
        text: "Something Went Wrong",
      });
      setLoading(false);
      console.log(error);
    }
  };
  const [location_name, setLocationName] = useState("");
  const [person_name, setPersonName] = useState("");
  const [email, setEmail] = useState('')

  const onLocationMenu = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("customer_email", email);
    formData.append("person_name", person_name);
    formData.append("location", localStorage.getItem("location"));
    formData.append("type", 0);

    try {
      const response = await axios({
        method: "post",
        url: `${config.apiUrl}/download_menu`,
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {

        navigate("/thankyou", {
          state: {
            message: "Menu has been sent at " + email
          }
        })

        // swal({
        //   icon: "success",
        //   text: "Menu has been sent at " + email,
        // }).then((data) => {
        //   setLoading(false);

        //   // setLocationName("");
        //   // setPersonName("");
        //   // setEmail("")
        //   // if (locationToPdfUrl.hasOwnProperty(location_name)) {
        //   //   window.open(locationToPdfUrl[location_name], "_blank");
        //   // } else {

        //   // }
        // });
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const locationToPdfUrl = {
    Ajax: rollzMenu,
    Mississauga: rollzMenu,
    Scarborough: rollzMenu,
    Collingwood: rollzMenu,
    Regina: commonMenu,
    Edmonton: rollzMenu,
    Guelph: commonMenu,
    Vaughan: rollzMenu,
    Windsor: commonMenu,
    Kitchener: rollzMenu,
    Brantford: commonMenu,
    Peterborough: rollzMenu,
    Halifax: rollzMenu,
    NorthParkDriveBrampton: rollzMenu,
  };
  return (
    <div>
      <Helmet>
        <title>

          Best Halal Ice Cream Shop Near Me in Toronto | Rollz Ice Cream
        </title>
        <meta
          name="description"
          content="Download the Rollz Ice Cream menu for Halifax, Peterborough, Brantford, Brampton, Kitchener, Windsor, Vaughan, Guelph, Regina and for many more locations in Canada."
        />
        <meta
          property="og:title"
          content="Best Halal Ice Cream Shop Near Me in Toronto | Rollz Ice Cream"
        />
        <meta
          property="og:description"
          content="Download the Rollz Ice Cream menu for Halifax, Peterborough, Brantford, Brampton, Kitchener, Windsor, Vaughan, Guelph, Regina and for many more locations in Canada."
        />
      </Helmet>
      {isLoading && <Loader />}
      <Navbar />
      {/* <Banner /> */}

      <div className="content-wrapper" style={{ marginTop: "-20px" }}>
        <div className="container">
          <div className="contain-padi">
            <div className="franchise-head">
              <h1>Menu</h1>
              <div className="border-head-bottom"></div>
            </div>
            <div className="mt-3">
              <div className="bg-menu-image mt-4">
                <div className="bd-menu-box">
                  <div className="row">
                    {categoryList.slice(0, 8).map((obj, i) => {
                      if (obj.is_active == 1) {
                        return (
                          <Link
                            to={`/${obj.slug}`}
                            className="col-lg-3 mb-5 text-decoration-none"
                            key={i}
                          >
                            <div className="menu-box">
                              <img
                                src={obj.url}
                                alt="Rollz Ice Cream"
                                className=""
                              />
                              <h5>{obj.menu_name}</h5>
                            </div>
                          </Link>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <div className="">
                  <form onSubmit={onLocationMenu}>
                    <div className="cta-whatsapp">
                      <div className="text-center whatsapp-menu">
                        <h5>
                          Get Our Menu {" "}
                        </h5>
                      </div>
                      <div className=" row align-items-center">
                        <div className="col-sm-5 form-name">
                          <input
                            className="form-rollz mt-md-0"
                            placeholder="Enter Name"
                            type="text"
                            value={person_name}
                            onChange={(e) => setPersonName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-sm-5 form-name">
                          <input
                            className="form-rollz mt-md-0"
                            placeholder="Enter Email Address"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-sm-2">
                          <div>
                            <button className="download-whatsapp">
                              Get Menu
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="bd-content-small mt-5">
                <div>
                  <h3>Best Dessert in Toronto & GTA</h3>
                  <p>
                    Looking for the ultimate dessert experience in Canada? Look
                    no further than Rollz Ice Cream! Our menu features a
                    mouthwatering selection of delectable treats, including
                    rolled ice cream, milkshakes, waffles, cheesecakes, pints,
                    popping Soda, falooda, and ice cream cake—all made with the
                    freshest ingredients. If you’re craving a sweet treat, we’ve
                    got the perfect desserts for you.
                  </p>
                  <p>
                    You can indulge in our delectable treats at any of our
                    convenient store locations across Canada, including Ajax,
                    Brantford, Collingwood, Edmonton, Guelph, Brampton,
                    Kitchener, Mississauga, Regina, Scarborough, Vaughan, and
                    Windsor. Rollz Ice Cream—your go-to destination for sweet
                    satisfaction!
                  </p>
                </div>

                <div>
                  <div className="d-flex justify-content-center mt-5">
                    <div className="me-4">
                      <Link
                        to={`https://www.facebook.com/rollzicecream`}
                        target="_blank"
                      >
                        <img
                          src={Facebook}
                          alt="Rollz Ice Cream"
                          width="50"
                          height="50"
                        />
                      </Link>
                    </div>
                    <div className="me-4">
                      <Link
                        to={`https://www.instagram.com/rollzicecreams/`}
                        target="_blank"
                      >
                        <img
                          src={Instagram}
                          alt="Rollz Ice Cream"
                          width="50"
                          height="50"
                        />
                      </Link>
                    </div>
                    <div className="me-4">
                      <Link
                        to={`https://www.tiktok.com/@rollzicecream`}
                        target="_blank"
                      >
                        <img
                          src={TikTok}
                          alt="Rollz Ice Cream"
                          width="50"
                          height="50"
                        />
                      </Link>
                    </div>
                    <div className="me-4">
                      <Link
                        to={`https://www.youtube.com/@rollzicecream`}
                        target="_blank"
                      >
                        <img
                          src={YouTube}
                          alt="Rollz Ice Cream"
                          width="50"
                          height="50"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Menu;
